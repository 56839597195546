<template>
    <el-dialog tabindex="-1" width="800px" top="40px" title="Granska Förvaltningsberättelsen" :visible.sync="visible" :before-close="closeModal" :close-on-click-modal="false">
        <div class="border border-indigo-600 p-4" v-if="annualReportData">
            <div class="flex justify-between p-8">
                <div class="flex flex-col">
                    <p>{{ clientInfo.name }}</p>
                    <p>Org.nr {{ clientInfo.registrationNumber }}</p>
                </div>
                <p>2 (8)</p>
            </div>
            <div class="px-8 pb-8">
                <p class="text-xs">Styrelsen för {{ clientInfo.name }} avger följande årsredovisningen för räkenskapsåret {{ clientInfo.financialYearStart }} - {{ clientInfo.financialYearEnd }}.</p>
                <p v-if="annualReportData.annualReportSek && annualReportData.addAnnualReportIsSek" class="pt-4 text-xs pr-5 break-normal">{{ annualReportData.annualReportSek }}</p>
                <h1 class="pt-6 text-lg font-bold">Förvaltningsberättelse</h1>
                <p class="pt-4 text-base font-bold">{{ annualReportData.businessTitle }}</p>
                <div v-if="annualReportData.addGeneralBusinessInfo">
                    <h2 class="pt-2 font-bold font-sm">{{ annualReportData.generalBusinessTitle }}</h2>
                    <p class="pt-2 text-xs pr-5 break-normal">{{ annualReportData.generalBusinessInfo }}</p>
                </div>
                <div v-if="annualReportData.addResidenceInfo">
                    <p class="pt-2 text-xs">{{ annualReportData.residenceInfo }}</p>
                </div>
                <div v-if="annualReportData.addFinancialYearEventTitle">
                    <h2 class="pt-2 font-bold font-sm">Väsentliga händelser under räkenskapsåret</h2>
                    <p class="pt-2 text-xs pr-5 break-normal">{{ annualReportData.financialYearEventTitle }}</p>
                </div>

                <div class="pt-4 text-xs" v-if="annualReportData.addYearlyOverview">
                    <table style="border-collapse: collapse;">
                        <tr v-for="(item, index) in annualReportData.yearlyOverview" :key="index" :style="{ 'font-weight': index === 0 ? 'bold' : 'normal' }">
                            <td style="padding: 4px 8px 4px 0px">{{ item.title }}</td>
                            <td style="width: 80px; text-align: right;">{{ item.annualReportYear }}</td>
                            <td style="width: 80px; text-align: right;">{{ item.annualReportYearMinusOne }}</td>
                            <td style="width: 80px; text-align: right;">{{ item.annualReportYearMinusTwo }}</td>
                            <td style="width: 80px; text-align: right;">{{ item.annualReportYearMinusThree }}</td>
                        </tr>
                    </table>

                    <div class="pt-4 text-xs">{{ annualReportData.yearlyOverviewComment }}</div>
                </div>
                <div v-if="annualReportData.addChangesInEquity">
                    <h2 class="pt-2 font-bold font-sm">Förändringar i eget kapital</h2>
                    <div class="pt-4" v-if="annualReportData && annualReportData.changesInEquityTitle">
                        <table>
                            <tr class="text-xs">
                                <td style="padding-left: 130px; padding-right: 10px">{{ annualReportData.changesInEquityTitle.titleChangesInEquityOne }}</td>
                                <td style="width: 80px; text-align: right;">{{ annualReportData.changesInEquityTitle.titleChangesInEquityTwo }}</td>
                                <td style="width: 75px; text-align: right; padding-right: 5px">{{ annualReportData.changesInEquityTitle.titleChangesInEquityThree }}</td>
                                <td style="width: 75px; text-align: right; padding-right: 5px">{{ annualReportData.changesInEquityTitle.titleChangesInEquityFour }}</td>
                                <td style="width: 70px; text-align: right;  padding-right: 5px">{{ annualReportData.changesInEquityTitle.titleChangesInEquityFive }}</td>
                                <td style="width: 78px; text-align: right;">{{ annualReportData.changesInEquityTitle.titleChangesInEquitySix }}</td>
                                <td style="width: 75px; text-align: right;">{{ annualReportData.changesInEquityTitle.titleChangesInEquitySeven }}</td>
                            </tr>
                        </table>
                    </div>
                    <div v-if="annualReportData && annualReportData.changesInEquity && annualReportData.changesInEquity.changesInEquityDetails">
                        <table style="border-collapse:collapse">
                            <tr class="text-xs" v-for="(item, index) in annualReportData.changesInEquity.changesInEquityDetails" :key="index">
                                <td style="padding-top: 8px" class="break-normal">{{ item.changesInEquityDescription }}</td>
                                <td style="width: 80px; text-align: right;">{{ item.changesInEquityOne }}</td>
                                <td style="width: 80px; text-align: right;">{{ item.changesInEquityTwo }}</td>
                                <td style="width: 80px; text-align: right;">{{ item.changesInEquityThree }}</td>
                                <td style="width: 80px; text-align: right;">{{ item.changesInEquityFour }}</td>
                                <td style="width: 80px; text-align: right;">{{ item.changesInEquityFive }}</td>
                                <td style="width: 80px; text-align: right;">{{ item.changesInEquitySix }}</td>
                                <td style="width: 80px; text-align: right;">{{ item.changesInEquitySeven }}</td>
                            </tr>
                        </table>
                    </div>
                    <p class="pt-4 text-xs">{{ annualReportData.changesInEquityComment }}</p>
                </div>
                <div v-if="annualReportData && annualReportData.incomeStatementAndProfitDistributions && annualReportData.addIncomeStatementAndProfitDistribution">
                    <h2 class="pt-2 font-bold font-sm">Resultatdisposition</h2>
                    <table style="border-collapse: collapse;">
                        <tr class="text-xs" v-for="(item, index) in annualReportData.incomeStatementAndProfitDistributions.profitDistributions" :key="index">
                            <td style="padding: 4px 8px 4px 0px">{{ item.profitDistributionTitle }}</td>
                            <td style="width: 120px; text-align: right;">{{ item.rightValue }}</td>
                        </tr>
                    </table>

                    <p
                        class="text-xs pt-2"
                        v-if="annualReportData && annualReportData.incomeStatementAndProfitDistributions && annualReportData.incomeStatementAndProfitDistributions.shareholderDividend != null"
                    >
                        Till aktieägare utdelas {{ annualReportData.incomeStatementAndProfitDistributions.shareholderDividend }} kronor per aktie
                    </p>

                    <p
                        class="text-xs pt-2"
                        v-if="annualReportData && annualReportData.incomeStatementAndProfitDistributions && annualReportData.incomeStatementAndProfitDistributions.boardAuthorizedToSetPaymentDate"
                    >
                        Styrelsen föreslås bemyndigas att besluta om tidpunkt då utdelningen skall betalas.
                    </p>

                    <p class="text-xs pt-2" v-if="annualReportData && annualReportData.incomeStatementAndProfitDistributions && annualReportData.incomeStatementAndProfitDistributions.setPaymentDay">
                        Utbetalningen skall betalas till aktieägarna senast {{ formattedDate }}.
                    </p>
                    <p class="pt-4 text-xs pr-5 break-normal">{{ annualReportData.incomeStatementAndProfitDistributions.statementFromBoard }}</p>
                </div>
                <p class="pt-4 text-xs">{{ annualReportData.completion }}</p>
            </div>
        </div>

        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal" type="default">Stäng</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        clientInfo: {
            type: Object,
        },
        annualReportData: {
            type: Object,
        },
    },
    methods: {
        closeModal() {
            this.$emit("close");
        },
    },
    computed: {
        formattedDate() {
            const date = new Date(this.annualReportData.incomeStatementAndProfitDistributions.setPaymentDayDate);
            return date.toLocaleDateString("en-CA");
        },
    },
};
</script>
